<template>
  <div class="edit_box  mrb20">
    <div class="flex">
      <div class="mrr20 ">
        <el-button icon="el-icon-plus" style="margin-left: 20px;" @click="originalVisible = true">添加类目</el-button>
        <div class="mrr20  mrt20 left_box hidden-scrollbar">
          <div class="flexcc size16 left_box_item" v-for="(item,index) in classData" :key="index">
            <el-button type="text" :style="{  color: param_id!=item.id?'#000':'' }" @click="searchClick(item.id)">{{ item.name }}</el-button>
          </div>
        </div>
      </div>
      <div class="" style="width: 85%;">
        <div class="mrb20">
          <el-button icon="el-icon-plus" style="margin-left: 20px;" @click="edit()">添加参数名称</el-button>
        </div>
        <div>
          <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark">
            <el-table-column label="序号" prop="id" align="center">
            </el-table-column>
            <el-table-column prop="value" label="参数名称" align="center">
            </el-table-column>
            <el-table-column prop="sort" label="排序" align="center">
            </el-table-column>
            <el-table-column fixed="right" label="操作" align="center">
              <template slot-scope="scope">
                <el-button size="mini" icon="el-icon-edit" type="primary" @click="edit(scope.row)">编辑</el-button>
                <el-button size="mini" type="danger" icon="el-icon-delete" @click="handleDelete(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <el-dialog title="类目" :visible.sync="originalVisible" width="40%">
      <el-form ref="classForm" :model="classForm" label-width="100px">
        <el-form-item label="类目名称" required>
          <el-input v-model="classForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="originalVisible = false">取 消</el-button>
        <el-button type="primary" @click="addClass()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加参数名称 -->
    <el-dialog title="添加参数" :visible.sync="parameterVisible" width="40%">
      <el-form ref="parameterForm" :model="parameterForm" label-width="100px">
        <el-form-item label="参数名称" required>
          <el-input v-model="parameterForm.value"></el-input>
        </el-form-item>
        <el-form-item label="排序" required>
          <el-input v-model="parameterForm.sort"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="parameterVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">{{ dialogTitle }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      role_id: "",
      classData: [],
      param_id: "",
      originalVisible: false,
      parameterVisible: false,
      classsName: "",
      classForm: {
        name: "",
      },
      parameterForm: {
        value: "",
        id: "",
        sort: "",
      },
      ifMaterialEdit: 0, // 0表示编辑，1表示新增
      dialogTitle: "",
      tableData: [],
    };
  },

  created() {
    this.getClass();
  },
  mounted() {},
  computed: {},

  methods: {
    async addClass() {
      const data = await this.$Api.goodsGoodsParamAdd(this.classForm);
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      this.$message.success("新增成功");
      this.originalVisible = false;
      this.getClass();
    },
    async getClass() {
      const { data } = await this.$Api.goodsGoodsParam();
      //   console.log("data1341", data);
      this.classData = data.list;
      this.getList();
    },
    searchClick(row) {
      console.log("选择的id", row);
      this.param_id = row;
      this.getList();
    },
    async getList() {
      console.log("this.param_id", this.param_id);
      if (!this.param_id) {
        this.param_id = this.classData[0].id;
        console.log("this.classData[0].id", this.classData[0].id);
        console.log("默认的id", this.param_id);
      }
      const { data } = await this.$Api.goodsGoodsParamText({
        param_id: this.param_id,
      });
      this.tableData = data.list;
      console.log("获取值", data);
    },
    // 新增编辑
    edit(row) {
      if (row) {
        // console.log("点击编辑", row);
        this.ifMaterialEdit = 0;
        this.parameterForm.id = row.id;
        this.parameterVisible = true;
        this.dialogTitle = "编辑";
        this.parameterForm = { ...row };
      } else {
        console.log("新增咯");
        this.ifMaterialEdit = 1;
        this.parameterVisible = true;
        this.dialogTitle = "新增";
        this.parameterForm = {
          value: "",
          id: "",
          sort: "",
        };
      }
    },
    async onSubmit() {
      //   const param_id = this.param_id;
      //   if (this.param_id == "") {
      //     this.param_id = this.classData[0].id;
      //     console.log("this.classData[0].id", this.classData[0].id);
      //     console.log("默认的id", this.param_id);
      //   }
      console.log("添加到", this.param_id);
      // 新增接口对接
      if (this.ifMaterialEdit == 1) {
        // console.log("新增管理", this.originaForm);
        const data = await this.$Api.goodsGoodsParamTextAdd({
          param_id: this.param_id,
          ...this.parameterForm,
        });
        if (data.code !== 200) {
          this.$message.error(data.msg);
          return;
        }
        this.$message.success("新增成功");
        this.parameterVisible = false;
        this.getList();
      } else if (this.ifMaterialEdit == 0) {
        // 编辑接口对接
        const data = await this.$Api.goodsGoodsParamTextEdit({
          param_id: this.param_id,
          ...this.parameterForm,
        });
        this.$message.success("编辑成功");
        this.parameterVisible = false;
        this.getList();
      }
    },
    handleSizeChange(val) {
      // console.log("val", val);
      this.pageInfo.limit = val;
      this.pageInfo.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log("val", val);
      this.pageInfo.page = val;
      this.getList();
    },
    // 单个删除
    handleDelete(row) {
      this.$confirm("删除后无法恢复,请谨慎操作", "请确认是否删除该参数名称", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const data = await this.$Api.goodsGoodsParamTextDel({
            id: row,
            param_id: this.param_id,
          });
          // console.log("删除成功", data);
          if (data.code != 200) {
            return this.$message({
              message: data.msg,
              type: "error",
            });
          }
          this.$message({
            message: data.msg,
            type: "success",
          });
          this.getList(this.pageInfo);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: data.msg,
          });
        });
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-tree-node {
  display: flex;
  margin-bottom: 10px;
  margin-right: 10px;
}
.edit_box {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #ffffff;
}
.left_box {
  height: 600px;
  overflow: scroll;
  border-right: 1px solid #66b1ff;
  .left_box_item {
    width: 150px;
    height: 38px;
    // border-bottom: 1px solid #66b1ff;
  }
}

.left_box_item:last-child {
  border-bottom: none;
}
.right_box {
  padding: 20px;
  border: 1px solid #d3cccc;
}
</style>